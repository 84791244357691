window.initMap = function () {
    $(function () {
        const els = document.getElementsByClassName('map-container');

        for (let map of els) {
            if (!!map) {
                const lat = parseFloat(map.dataset.lat);
                const lng = parseFloat(map.dataset.lng);
                const zoom = parseFloat(map.dataset.zoom);
                const pointers = JSON.parse(map.dataset.pointers);
                const image = map.dataset.image;

                map = new google.maps.Map(map, {
                    center: {lat: lat, lng: lng},
                    zoom: zoom
                });

                if (pointers.length > 0) {
                    for (let point in pointers) {
                        let pointerLat = parseFloat(pointers[point]['lat']);
                        let pointerLng = parseFloat(pointers[point]['lng']);

                        new google.maps.Marker({
                            position: {lat: pointerLat, lng: pointerLng},
                            map,
                            icon: image,
                        });
                    }
                } else {
                    new google.maps.Marker({
                        position: {lat: lat, lng: lng},
                        map,
                    });
                }
            }
        }
    });
}
