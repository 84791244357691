import 'bootstrap/js/dist/base-component';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import axios from 'axios';

window.$ = window.jQuery = require('jquery');

require('./components/Map')
require('./components/Menu')
require('./components/Schedule')
require('./components/Parallax')

$(function () {
    let openEventModal = function () {
        let query = new URLSearchParams(window.location.search);

        if (query.get('open') === 'modal') {
            let event = query.get('event');

            let $btn = $(`[data-toggle="event-detail"][data-event="${event}"]`);

            $btn.trigger('click');

            let $modal = $($btn.data('target'));

            $modal.on('hidden.bs.modal', async function () {
                let href = window.location.href.replace(window.location.search, '');

                await window.history.replaceState({}, '', href);

                $(this).unbind('hidden.bs.modal');
            });
        }
    };

    $(document).on('click', '[data-toggle="event-detail"]', async function (e) {
        e.preventDefault();

        let $btn = $(this);
        let $modal = $($btn.data('target'));
        let $loader = $('.loader', $modal)

        $modal.modal('show');

        let $body = $('.modal-body', $modal);

        let {data} = (await axios.get(`/wp-json/schedule/event/${$btn.data('event')}`, {
            params: {
                bg: $btn.data('bg')
            }
        }));

        $body.html(data);

        setTimeout(function () {
            $loader.css('display', 'none');
        }, 150)

        setTimeout(function () {
            $body.css('opacity', 1);
        }, 250)

    });

    $(document).on('click', '[data-toggle="speaker-detail"]', async function (e) {
        e.preventDefault();

        let $btn = $(this);
        let $modal = $($btn.data('target'));
        let $loader = $('.loader', $modal)

        $modal.modal('show');

        let $body = $('.modal-body', $modal);

        $body.html('<div class="loader"></div>');

        let {data} = (await axios.get(`/wp-json/speaker/${$btn.data('speaker')}`));

        $body.html(data);

        setTimeout(function () {
            $loader.css('display', 'none');
        }, 150)

        setTimeout(function () {
            $body.css('opacity', 1);
        }, 250)
    });

    openEventModal();

    if (document.location.pathname === '/') {
        $('.canvas--container').addClass('home-page');
    }

    document.addEventListener('shown.bs.modal', function (event) {
      $('html').addClass('overflow-hidden')
    })

    document.addEventListener('hidden.bs.modal', function (event) {
      $('html').removeClass('overflow-hidden')
    })
});
