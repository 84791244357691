$(function () {
    let $w_window = $(window).width();

    let $trakcs = $('[data-toggle="collapse-track"]');

    let openCollapseTrack = function () {
        resetSameHeight();

        let query = new URLSearchParams(window.location.search);
        let target_to_open = '#collapse-track-' + query.get('id');

        $trakcs.each(function (i) {
            let $element = $(this);

            let bs_target = $element.data('bs-target');
            let $target = $(bs_target);


            if ((query.get('open') !== 'track' && i !== 0 || query.get('open') === 'track' && target_to_open !== bs_target)) {
                $element.attr('aria-expanded', false)
                $target.collapse('hide');
            } else {
                $element.attr('aria-expanded', true)
                $target.collapse('show');
            }

        });

        if (query.get('open') === 'track') {

            setTimeout(function () {
                $('html,body').animate({
                    scrollTop: $(target_to_open).offset().top
                }, 500);
            }, 1000);
        }

        if (query.get('open') === 'track') {
            let href = window.location.href.replace(window.location.search, '');
            window.history.replaceState({}, '', href);
        }
    };

    $trakcs.on('click', function (e) {
        e.preventDefault();

        let $element = $(this);
        let $target = $($element.data('bs-target'));

        if ($w_window < 1200) {
            $element.attr('aria-expanded', !$target.hasClass('show'))
            $target.collapse('toggle');
        }
    });

    function openCollapsedTracks() {
        $trakcs.each(function (i) {
            let $element = $(this);

            let bs_target = $element.data('bs-target');
            let $target = $(bs_target);

            $element.attr('aria-expanded', true)
            $target.collapse('show');

        });
    }

    function setSameHeight() {
        let $sameHeightDivs = $('.uq-information-container:not(.uq-rest)');
        let minHeight = 0;

        $sameHeightDivs.each(function () {
            minHeight = Math.max(minHeight, $(this).outerHeight());
        });

        $sameHeightDivs.css({'min-height': minHeight + 'px'});
    }

    function resetSameHeight() {
        let $sameHeightDivs = $('.uq-information-container:not(.uq-rest)');
        let minHeight = 'unset';

        $sameHeightDivs.css({'min-height': 'unset'});
    }


    window.onresize = (e) => {
        $w_window = $(window).width();

        if ($w_window > 1200) {
            resetSameHeight();
            setSameHeight();
            openCollapsedTracks();
        }

        if ($w_window <= 1200) {
            openCollapseTrack()
        }
    }

    window.onload = () => {
        $w_window = $(window).width();

        if ($w_window > 1200) {
            setSameHeight();
        }

        if ($w_window <= 1200) {
            openCollapseTrack();
        }
    }
});
