import * as basicScroll from 'basicscroll'

$(function () {
    let parallax_sections = document.querySelectorAll('.uq-parallax');

    for (let parallax_section of parallax_sections) {
        let _from = parallax_section.dataset.from || '0px';
        let _to = parallax_section.dataset.to || '100px';

        let instance = basicScroll.create({
            elem: parallax_section,
            from: 'middle-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--ty': {
                    from: '-100px',
                    to: '0',
                }
            }
        })

        instance.start();
    }

    let parallax_alt_sections = document.querySelectorAll('.uq-parallax-alt');

    for (let parallax_section of parallax_alt_sections) {
        let _from = parallax_section.dataset.from || '0px';
        let _to = parallax_section.dataset.to || '100px';

        let instance2 = basicScroll.create({
            elem: parallax_section,
            from: 'top-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--ty': {
                    from: '-100px',
                    to: '200px',
                }
            }
        })

        instance2.start();
    }
})